main {
  display: block;
}
/**
* Template Name: Eterna - v4.8.1
* Template URL: https://bootstrapmade.com/eterna-free-multipurpose-bootstrap-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #e96b56;
  text-decoration: none;
}

a:hover {
  color: #e6573f;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

/*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
.back-to-top {
  position: absolute !important;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  /* bottom: 15px; */
  z-index: 996;
  background: #e96b56;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
  margin-top: -165px !important;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #ee8b7a;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
  # Top Bar
  --------------------------------------------------------------*/
#topbar {
  background: #545454;
  padding: 10px 0;
  font-size: 14px;
  min-height: 50px;
}

#topbar .contact-info i {
  font-style: normal;
  color: #e96b56;
}

#topbar .contact-info i a,
#topbar .contact-info i span {
  padding-left: 5px;
  color: #fff;
}

#topbar .contact-info i a {
  line-height: 0;
  transition: 0.3s;
}

#topbar .contact-info i a:hover {
  color: #e96b56;
}

#topbar .social-links a {
  color: #bababa;
  line-height: 0;
  transition: 0.3s;
  margin-left: 10px;
}

#topbar .social-links a:hover {
  color: white;
}

/*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
#header {
  height: 70px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  background: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

#header .logo h1 {
  font-size: 26px;
  padding: 0 0 0 8px;
  margin: 11px 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  border-left: 8px solid #e96b56;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #545454;
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

.scrolled-offset {
  margin-top: 70px;
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/**
  * Desktop Navigation 
  */
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: #545454;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #e96b56;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  background: #e96b56;
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 4px;
  color: #fff;
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #ec7f6d;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 24px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  color: #545454;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #e96b56;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
  color: #545454;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(59, 59, 59, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #545454;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #e96b56;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #e96b56;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 70vh;
  background-color: rgba(59, 59, 59, 0.8);
  overflow: hidden;
  padding: 0;
  margin-bottom: -120px;
  position: relative;
  z-index: 1;
}

#hero .carousel-item {
  width: 100%;
  height: 70vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center center;
}

#hero .carousel-item::before {
  content: "";
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

#hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  padding-bottom: 120px;
}

#hero .carousel-content {
  text-align: center;
}

#hero h2 {
  color: #2e2e2e;
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 700;
}

#hero h2 span {
  color: #e96b56;
}

#hero p {
  width: 80%;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: arken(#545454, 15);
}

#hero .carousel-inner .carousel-item {
  transition-property: opacity;
  background-position: center top;
}

#hero .carousel-inner .carousel-item,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  opacity: 0;
}

#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-start,
#hero .carousel-inner .carousel-item-prev.carousel-item-end {
  opacity: 1;
  transition: 0.5s;
}

#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev,
#hero .carousel-inner .active.carousel-item-start,
#hero .carousel-inner .active.carousel-item-end {
  left: 0;
  transform: translate3d(0, 0, 0);
}

#hero .carousel-control-prev,
#hero .carousel-control-next {
  width: 10%;
}

#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: none;
  font-size: 48px;
  line-height: 1;
  width: auto;
  height: auto;
  color: #545454;
}

#hero .carousel-indicators li {
  cursor: pointer;
  background: #e96b56;
  overflow: hidden;
  border: 0;
  width: 12px;
  height: 12px;
  border-radius: 50px;
  margin-bottom: 180px;
  opacity: 0.2;
}

#hero .carousel-indicators li.active {
  opacity: 1;
}

#hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #e96b56;
  background: #e96b56;
}

#hero .btn-get-started:hover {
  background: transparent;
  color: #e96b56;
  text-decoration: none;
}

@media (max-height: 768px), (max-width: 1200px) {
  #hero {
    margin-bottom: 60px;
  }

  #hero .carousel-container {
    padding-bottom: 0;
  }

  #hero .carousel-indicators li {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  #hero {
    height: 90vh;
  }

  #hero .carousel-item {
    height: 90vh;
  }
}

@media (min-width: 1024px) {
  #hero p {
    width: 50%;
  }

  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
}

/*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
section {
  padding: 0 0 80px 0;
}

.section-bg {
  background-color: white;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  font-family: "Poppins", sans-serif;
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #e96b56;
  bottom: 0;
  left: calc(50% - 25px);
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
.breadcrumbs {
  padding: 20px 0 20px 0;
  background: #f7f7f7;
  border-bottom: 1px solid #ededed;
  margin-bottom: 40px;
}

.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 700;
  color: #545454;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 5px 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6e6e6e;
  content: "/";
}

/*--------------------------------------------------------------
  # Featured
  --------------------------------------------------------------*/
.featured {
  position: relative;
  z-index: 2;
}

.featured .icon-box {
  padding: 40px 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background: #fff;
  transition: all ease-in-out 0.3s;
  height: 100%;
}

.featured .icon-box i {
  color: #e96b56;
  font-size: 42px;
  margin-bottom: 15px;
  display: block;
  line-height: 0;
}

.featured .icon-box h3 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 20px;
}

.featured .icon-box h3 a {
  color: #545454;
  transition: 0.3s;
}

.featured .icon-box p {
  color: #545454;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.featured .icon-box:hover {
  background: #e96b56;
}

.featured .icon-box:hover i,
.featured .icon-box:hover h3 a,
.featured .icon-box:hover p {
  color: #fff;
}

/*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
  color: #545454;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-bottom: 10px;
}

.about .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #e96b56;
}

.about .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  # Our Services
  --------------------------------------------------------------*/
.services .icon-box {
  text-align: center;
  border: 1px solid #ededed;
  padding: 80px 20px;
  transition: all ease-in-out 0.3s;
}

.services .icon-box .icon {
  margin: 0 auto;
  width: 64px;
  height: 64px;
  background: #e96b56;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  transform-style: preserve-3d;
}

.services .icon-box .icon i {
  color: #fff;
  font-size: 28px;
}

.services .icon-box .icon::before {
  position: absolute;
  content: "";
  left: -8px;
  top: -8px;
  height: 100%;
  width: 100%;
  background: #fbe2dd;
  border-radius: 5px;
  transition: all 0.3s ease-out 0s;
  transform: translateZ(-1px);
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 24px;
}

.services .icon-box h4 a {
  color: #545454;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.services .icon-box:hover {
  background: #e96b56;
  border-color: #e96b56;
}

.services .icon-box:hover .icon {
  background: #fff;
}

.services .icon-box:hover .icon i {
  color: #e96b56;
}

.services .icon-box:hover .icon::before {
  background: #ef9383;
}

.services .icon-box:hover h4 a,
.services .icon-box:hover p {
  color: #fff;
}

/*--------------------------------------------------------------
  # Our Clients
  --------------------------------------------------------------*/
.clients .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.clients .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #e96b56;
}

.clients .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #e96b56;
}

.clients .swiper-slide img {
  opacity: 0.5;
  transition: 0.3s;
  filter: grayscale(100);
}

.clients .swiper-slide img:hover {
  filter: none;
  opacity: 1;
}

/*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
.counts .count-box {
  box-shadow: -10px -5px 40px 0 rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
}

.counts .count-box i {
  display: block;
  font-size: 30px;
  color: #e96b56;
  float: left;
  line-height: 0;
}

.counts .count-box span {
  font-size: 42px;
  line-height: 24px;
  display: block;
  font-weight: 700;
  color: #545454;
  margin-left: 50px;
}

.counts .count-box p {
  padding: 30px 0 0 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
}

.counts .count-box a {
  font-weight: 600;
  display: block;
  margin-top: 20px;
  color: #7a7a7a;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  transition: ease-in-out 0.3s;
}

.counts .count-box a:hover {
  color: #a1a1a1;
}

/*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
  overflow: hidden;
}

.testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 40px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  position: relative;
  background: #fff;
}

.testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50px;
  border: 6px solid #fff;
  float: left;
  margin: 0 10px 0 0;
}

.testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
  color: white;
  font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.testimonials .testimonial-item p {
  font-style: italic;
  margin: 15px 0 0 0;
  padding: 0;
}

/*--------------------------------------------------------------
  # Our Skills
  --------------------------------------------------------------*/
.skills .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #545454;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}

.skills .content ul {
  list-style: none;
  padding: 0;
}

.skills .content ul li {
  padding-bottom: 10px;
}

.skills .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #e96b56;
}

.skills .content p:last-child {
  margin-bottom: 0;
}

.skills .progress {
  height: 62px;
  display: block;
  background: none;
  border-radius: 0;
}

.skills .progress .skill {
  padding: 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #545454;
}

.skills .progress .skill .val {
  float: right;
  font-style: normal;
}

.skills .progress-bar-wrap {
  background: #e0e0e0;
}

.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: 0.9s;
  background-color: #e96b56;
}

/*--------------------------------------------------------------
  # Portfolio
  --------------------------------------------------------------*/
.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 25px auto;
  list-style: none;
  text-align: center;
  background: white;
  border-radius: 50px;
  padding: 2px 15px;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 20px 12px 20px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  color: #444444;
  margin: 0 4px 8px 4px;
  transition: all ease-in-out 0.3s;
  border-radius: 50px;
  background: #f2f2f2;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  background: #e96b56;
  color: #fff;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(84, 84, 84, 0.6);
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(84, 84, 84, 0.6);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio .portfolio-wrap .portfolio-info::before {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 35px;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info::after {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
  color: #fff;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
  color: #e96b56;
}

.portfolio .portfolio-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info::before {
  top: 15px;
  left: 15px;
}

.portfolio .portfolio-wrap:hover .portfolio-info::after {
  bottom: 15px;
  right: 15px;
}

/*--------------------------------------------------------------
  # Portfolio Details
  --------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

/* .portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
} */

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #e96b56;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #e96b56;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(84, 84, 84, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
  # Pricing
  --------------------------------------------------------------*/
.pricing .row {
  padding-top: 40px;
}

.pricing .box {
  padding: 40px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
  background: #fff;
  text-align: center;
}

.pricing h3 {
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 28px;
}

.pricing h4 {
  font-size: 46px;
  color: #e96b56;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 25px;
}

.pricing h4 span {
  color: #bababa;
  font-size: 18px;
  display: block;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  padding-bottom: 12px;
}

.pricing ul i {
  color: #e96b56;
  font-size: 18px;
  padding-right: 4px;
}

.pricing ul .na {
  color: #ccc;
}

.pricing ul .na i {
  color: #ccc;
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .buy-btn {
  background: #545454;
  display: inline-block;
  padding: 8px 30px;
  border-radius: 20px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  transition: 0.3s;
}

.pricing .buy-btn:hover {
  background: #e96b56;
}

.pricing .featured {
  z-index: 10;
  margin: -30px -5px 0 -5px;
}

.pricing .featured .buy-btn {
  background: #e96b56;
}

.pricing .featured .buy-btn:hover {
  background: #ee8b7a;
}

@media (max-width: 992px) {
  .pricing .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 767px) {
  .pricing .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

/*--------------------------------------------------------------
  # Team
  --------------------------------------------------------------*/
.team .member {
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  background: #fff;
}

.team .member img {
  max-width: 60%;
  border-radius: 50%;
  margin: 0 0 30px 0;
}

.team .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
}

.team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
}

.team .member p {
  padding-top: 10px;
  font-size: 14px;
  font-style: italic;
  color: #aaaaaa;
}

.team .member .social {
  margin-top: 15px;
}

.team .member .social a {
  color: #919191;
  transition: 0.3s;
}

.team .member .social a:hover {
  color: #e96b56;
}

.team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

/*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
}

.contact .info-box i {
  font-size: 32px;
  color: #e96b56;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #fef5f4;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
}

.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #e96b56;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  background: #e96b56;
  border: 0;
  border-radius: 50px;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #e6573f;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
  # Blog
  --------------------------------------------------------------*/

/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
#footer {
  background: #404040;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;

  bottom: 0;
  width: 100%;
  left: 0;
  position: relative; /* for the footer to move with the page size */
  /* min-height: 100%;  for the footer to be at the bottom */
  /* padding-bottom: 6rem;  
  margin-top: 35%;*/
}

#footer .footer-newsletter {
  padding: 50px 0;
  background: #404040;
}

#footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
}

#footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 8px;
  width: calc(100% - 140px);
}

#footer .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 30px;
  margin: 3px;
  background: #e96b56;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
}

#footer .footer-newsletter form input[type="submit"]:hover {
  background: #e6573f;
}

#footer .footer-top {
  background: #3b3b3b;
  border-top: 1px solid #474747;
  border-bottom: 1px solid #474747;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
  font-size: 18px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  color: #fff;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #545454;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #e96b56;
  color: #fff;
  text-decoration: none;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #ec7f6d;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  color: #e96b56;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
  line-height: 26px;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}

#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}

.rowCenter {
  justify-content: center;
  margin-top: 40px;
}
.MuiGrid-grid-xs-10 {
  max-width: 100%;
}
.align-items-center {
  align-items: center !important;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}

/* .portfolio-details .portfolio-details-slider img {
  width: 100%;
}

.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
} */

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #e96b56;
}

.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #e96b56;
}

.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(84, 84, 84, 0.08);
}

.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}

.portfolio-details .portfolio-description {
  padding-top: 30px;
}

.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
  # Pricing
  --------------------------------------------------------------*/
.pricing .row {
  padding-top: 40px;
}

.pricing .box {
  padding: 40px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
  background: #fff;
  text-align: center;
}

.pricing h3 {
  font-weight: 300;
  margin-bottom: 15px;
  font-size: 28px;
}

.pricing h4 {
  font-size: 46px;
  color: #e96b56;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 25px;
}

.pricing h4 span {
  color: #bababa;
  font-size: 18px;
  display: block;
}

.pricing ul {
  padding: 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}

.pricing ul li {
  padding-bottom: 12px;
}

.pricing ul i {
  color: #e96b56;
  font-size: 18px;
  padding-right: 4px;
}

.pricing ul .na {
  color: #ccc;
}

.pricing ul .na i {
  color: #ccc;
}

.pricing ul .na span {
  text-decoration: line-through;
}

.pricing .buy-btn {
  background: #545454;
  display: inline-block;
  padding: 8px 30px;
  border-radius: 20px;
  color: #fff;
  transition: none;
  font-size: 14px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  transition: 0.3s;
}

.pricing .buy-btn:hover {
  background: #e96b56;
}

.pricing .featured {
  z-index: 10;
  margin: -30px -5px 0 -5px;
}

.pricing .featured .buy-btn {
  background: #e96b56;
}

.pricing .featured .buy-btn:hover {
  background: #ee8b7a;
}

@media (max-width: 992px) {
  .pricing .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 767px) {
  .pricing .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}

@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}

@media (min-width: 992px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
}
@media (min-width: 992px) {
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

.rowCenter {
  justify-content: center;
}
/* -----------------------Details--------------- */
.details {
  width: 100%;
  display: flex;

  flex-wrap: wrap;
  padding: 50px;
}
.details .img-container {
  max-width: 400px;
  width: 100%;
  margin: 20px;
  height: 450px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.details .img-container:hover {
  background-size: 150%;
  border: 1px solid #ddd;
  cursor: zoom-in;
}

.box-details {
  max-width: 500px;
  width: 100%;
  margin: 20px;
}
.box-details h2 {
  text-transform: uppercase;
  color: darkblue;
  letter-spacing: 2px;
  font-size: 2rem;
}
.box-details button,
.box-details .cart {
  border: 1px solid #555;
  outline: none;
  background: #333;
  cursor: pointer;
  color: white;
  margin-right: 5px;
  margin-top: 10px;
}
.colors button,
.sizes button {
  width: 35px;
  height: 35px;
}
.box-details p {
  line-height: 1.5;
  margin: 10px 0;
  opacity: 0.8;
}
.box-details h3 {
  color: crimson;
}
.box-details .thumb {
  display: flex;
  cursor: pointer;
  /* background-color: white; */
}

.box-details .thumb img {
  width: 100px;
  height: 100px;
  display: block;
  object-fit: cover;
  margin: 10px;
  overflow: hidden;
  /* background-color: white; */
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.box-details .cart {
  padding: 10px 25px;
  text-transform: uppercase;
  letter-spacing: 2px;
  display: inline-block;
}
/* Similar product css */
.products .similarCard {
  max-width: 200px;
  width: 100%;
  overflow: hidden;
  height: 200px;
  box-shadow: 0 0 5px #ccc;
  padding: 15px;
  margin: 15px;
  cursor: pointer;
}
.products {
  width: 100%;
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  /*margin-top: 100px; */
  justify-content: center;
  margin-top: 10px;
}

.products .img-container {
  max-width: 150px;
  width: 100%;
  margin: 20px;
  height: 150px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.products .img-container {
  max-width: 150px;
  width: 100%;
  margin: 20px;
  height: 150px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.card img {
  width: 100%;
  height: 100%;
  max-height: 340px;
  display: block;
  object-fit: cover;
}
.card .box {
  margin: 15px 0;
}
.products .card {
  max-width: 370px;
  width: 100%;
  overflow: hidden;

  box-shadow: 0 0 5px #ccc;
  padding: 15px;
  margin: 10px;
  cursor: pointer;
}
.card img {
  width: 100%;
  height: 100%;
  max-height: 340px;
  display: block;
  object-fit: cover;
}
.card .box {
  margin: 15px 0;
}
.card .box h3 {
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.card .box p {
  margin: 10px 0;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  height: 70px;
}
.card .box h4 {
  color: crimson;
}
.card .box button {
  width: 100%;
  border: none;
  outline: none;
  background: blue;
  color: white;
  text-align: center;
  padding: 10px 0;
  margin: 10px 0;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.d-flex {
  display: flex !important;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

.align-items-center {
  align-items: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.d-flex {
  display: flex !important;
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
  }
}
#topbar .contact-info i {
  font-style: normal;
  color: #e96b56;
}

@media (min-width: 992px) {
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
}

.products .bigcard {
  max-width: 370px;
  width: 100%;
  overflow: hidden;

  box-shadow: 0 0 5px #ccc;
  padding: 15px;
  margin: 10px;
  cursor: pointer;
}

.calculateRow {
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
/* @media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}*/

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1200px;
  }
}

@media (min-width: 992px) {
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
}

.inputSubmit {
  position: sticky;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 30px;
  margin: 3px;
  background: #e96b56;
  color: #fff;
  transition: 0.3s;
  height: 40px;
}

.inputSubmit:hover {
  background: #e6573f;
}
footer {
  display: block;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-top-header {
  position: fixed;
  top: 40px;
  right: 0;
  left: 0;
  z-index: 1030;
}

.bordercolorcss {
  box-shadow: 0 0 2px #ccc;
  padding: 15px;
  margin: 10px;
}
.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 10px;
  position: relative;
}

.swiper-button-next {
  background-image: url(../../assets/icons/next-page-48.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
.swiper-button-prev {
  background-image: url(../../assets/icons/back-to-48.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
}
.swiper-button-next::after {
  display: none;
}
.swiper-button-prev::after {
  display: none;
}
.statusMsg {
  margin-top: 10rem;
  justify-content: center;
  text-align: center;
}
.backbutton {
  background-color: #e96b56;
  border-color: #e96b56;
  margin-right: 10px;
  color: white;
  border-radius: 10px;
  border-bottom: #e96b56;
  border-right: #e96b56;
  padding: 10px;
  width: 60px;
}

.backbutton:hover {
  background-color: #e96b56;
  border-color: #e96b56;
  margin-right: 10px;
  color: white;
  border-radius: 10px;
  border-bottom: #e96b56;
  border-right: #e96b56;
  padding: 10px;
  /* width: 60px; */
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.product-video {
  height: 550px !important;
  width: 100%;
}
.back-to-left {
  /* bottom: 15px; */
  z-index: 996;
  background: #e96b56;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
  color: white;
  font-size: 2rem;
}
.back-to-left:hover {
  background: #ee8b7a;
  color: #fff;
}

/* Mobile header email and ph no  */
@media (min-width: 320px) and (max-width: 767px) {
  .product-swiper-img {
    height: 340px !important;
    object-fit: unset !important;
  }
  .main-page-height {
    min-height: 73vh !important;
  }

  .header-info {
    display: block !important;
  }

  .header-mobile {
    margin-top: 10px;
    margin-left: 0px !important;
  }

  .fixed-top-header {
    top: 52px !important;
  }

  .swiper-wrapper {
    height: 340px !important;
  }
  .product-video {
    height: 340px !important;
    width: 100% !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .main-page-height {
    min-height: 80vh !important;
  }
}

/* Desktop header email and ph no  */
.product-swiper-img {
  height: 550px;
  width: 100%;
  object-fit: contain;
}

.header-info {
  display: flex;
  align-items: center;
}

.container-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-mobile {
  margin-left: 15px;
}
.product-video {
  height: 550px;
  width: 100%;
}

.somethingwentwrong {
  text-align: center;
  color: red;
  margin-top: 100px;
}

video {
  object-fit: cover;
}
.main-page-height {
  min-height: 66vh;
}

@media (min-width: 1500px) and (max-width: 1920px) {
  .main-page-height {
    min-height: 75vh;
  }
}


.view-detail-btn {
    background: #e96b56;
    display: inline-block;
    padding: 8px 30px;   
    color: #fff;
    transition: none;
    font-size: 14px;
    font-weight: 400;    
    transition: 0.3s;
}
.view-detail-btn:hover {
  background: #ee8b7a;
  color: white !important;
}