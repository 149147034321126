.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.text-store{
  color: #464b83e6;
    text-align: center;
}

/* .mainpage{
    background: #F5F5F5;
}
.mainpage::before{
    background-image: ;
} */
#content {
  width: 100%;
  height: 100vh;
  transition: all 0.3s;
  content: "";
  background-image:  url(./bgimgright.svg),url(./bgimgtopleft.svg),
  url(./bgimgright.svg),url(./bgimgtopleft.svg) !important;
  background-position: Top left, Top right , Bottom left ,Bottom right;
  background-repeat: no-repeat;
  position: relative;
  background-color: #F5F5F5;
  background-size:50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main{
align-items: center;
/* display: flex; */
justify-content: center;
}
.marsuplogo{
  height:100%;
  width: 380px;
}
h2{
  /* color: #ec516c; */
  font-weight: 700;
  font-size: 43px;
}
.shopbagimg{
  height: 380px;
  margin-left: 65px;
}
.imgbag{
  display: flex;
  justify-content: center;
}
.powerdby{
  position: absolute;
  bottom: 20px;
  right: 51px;
  font-weight: 500;

}
h4{
  font-size: 18px !important;
  text-align: center;
}
.arieotechimg{
  width: 160px;
}

@media only screen and (max-width: 768px){
  .marsuplogo {
    
    width: 225px !important;
}
.imgbag{
  height: 137px;
}
}

.logodiv{
  display: flex;
  align-items: center;
  justify-content: center;
}